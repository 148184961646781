import React from "react";
import {
  Card,
  Text,
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatArrow,
} from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { MdPersonOutline } from "react-icons/md";

const Home = () => {

  const options = {
    chart: {
      id: "line-bar",
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: ["#4318FF", "#39B8FF"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#7551FF",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      // type: "numeric",
      categories: [2017,2018,2019,2020,2021,2022,2023,2024],
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    // yaxis: {
    //   show: false,
    // },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
    },
    color: ["#7551FF", "#39B8FF"],
  }
  const series = [
    {
      name: "Total number of Students",
      data: [15000,16000,20000,22000,30000,25000,36000,35000]
    }
  ]

  return (
    <Box>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <Card py="15px">
          <Flex
            m="5px 15px"
            h="100%"
            align={{ base: "center", xl: "start" }}
            justify={{ base: "center", xl: "center" }}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"50%"}
              w="56px"
              h="56px"
              bg={"gray.100"}
            >
              <Icon w="32px" h="32px" as={MdPersonOutline} color="#422AFB" />
            </Flex>
            <Stat my="auto" ms={"18px"} textAlign={"left"}>
              <StatLabel
                lineHeight="100%"
                color={"#A3AED0"}
                fontSize={{ base: "sm" }}
              >
                {" "}Number of Students{" "}
              </StatLabel>
              <StatNumber color={"#1B2559"} fontSize={{ base: "2xl" }}>
                {" "}35000+{" "}
              </StatNumber>
              <Flex align="center">
                <StatArrow type="increase" />
                <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                  {" "}23%{" "}
                </Text>
                <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                  {" "}since last year{" "}
                </Text>
              </Flex>
            </Stat>
          </Flex>
        </Card>
        <Card py="15px">
          <Flex
            m="5px 15px"
            h="100%"
            align={{ base: "center", xl: "start" }}
            justify={{ base: "center", xl: "center" }}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"50%"}
              w="56px"
              h="56px"
              bg={"gray.100"}
            >
              <Icon w="32px" h="32px" as={MdPersonOutline} color="#422AFB" />
            </Flex>
            <Stat my="auto" ms={"18px"} textAlign={"left"}>
              <StatLabel
                lineHeight="100%"
                color={"#A3AED0"}
                fontSize={{ base: "sm" }}
              >
                {" "}Number of Students{" "}
              </StatLabel>
              <StatNumber color={"#1B2559"} fontSize={{ base: "2xl" }}>
                {" "}35000+{" "}
              </StatNumber>
              <Flex align="center">
                <StatArrow type="decrease" />
                <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                  {" "}23%{" "}
                </Text>
                <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                  {" "}since last year{" "}
                </Text>
              </Flex>
            </Stat>
          </Flex>
        </Card>
        <Card py="15px">
          <Flex
            m="5px 15px"
            h="100%"
            align={{ base: "center", xl: "start" }}
            justify={{ base: "center", xl: "center" }}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"50%"}
              w="56px"
              h="56px"
              bg={"gray.100"}
            >
              <Icon w="32px" h="32px" as={MdPersonOutline} color="#422AFB" />
            </Flex>
            <Stat my="auto" ms={"18px"} textAlign={"left"}>
              <StatLabel
                lineHeight="100%"
                color={"#A3AED0"}
                fontSize={{ base: "sm" }}
              >
                {" "}Number of Students{" "}
              </StatLabel>
              <StatNumber color={"#1B2559"} fontSize={{ base: "2xl" }}>
                {" "}35000+{" "}
              </StatNumber>
              <Flex align="center">
                <StatArrow type="decrease" />
                <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                  {" "}23%{" "}
                </Text>
                <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                  {" "}since last year{" "}
                </Text>
              </Flex>
            </Stat>
          </Flex>
        </Card>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <Card
          justifyContent='center'
          align='center'
          direction='column'
          w='100%'
          mb='0px'>
          
            <Chart
              options={options}
              series={series}
              type="line"
              width="700"
            />
          
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default Home;
