import React from "react";
import { ChakraProvider, Box, Grid, GridItem, theme } from "@chakra-ui/react";

import { Routes, Route } from "react-router-dom";

import { ColorModeSwitcher } from "./ColorModeSwitcher";
import Sidebar from "./Components/Sidebar";
import Home from "./Components/Home";
import TextToSpeech from "./Components/TextToSpeech";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" p={5}>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 6fr" }}
          gap={{ base: 0, md: 6 }}
        >
          <GridItem w="100%">
            <Sidebar />
          </GridItem>

          <GridItem w="100%">
            <Box textAlign="right">
              <ColorModeSwitcher justifySelf="flex-end" />
            </Box>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/app" element={<TextToSpeech />} />
            </Routes>
          </GridItem>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
