import React, { useState, useEffect } from "react";
import { Textarea, Flex, Button, Select } from '@chakra-ui/react'

const TextToSpeech = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [text, setText] = useState("");
  const [voice, setVoice] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);

    setUtterance(u);

    synth.addEventListener("voiceschanged", () => {
      const voices = synth.getVoices();
      setVoice(voices[0]);
    });

    return () => {
      synth.cancel();
      synth.removeEventListener("voiceschanged", () => {
        setVoice(null);
      });
    };
  }, [text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;

    if (isPaused) {
      synth.resume();
    } else {
      utterance.voice = voice;
      synth.speak(utterance);
    }

    setIsPaused(false);
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;
    setIsPaused(true);
    synth.pause();
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;
    setIsPaused(false);
    synth.cancel();
  };

  const handleVoiceChange = (event) => {
    const voices = window.speechSynthesis.getVoices();
    setVoice(voices.find((v) => v.name === event.target.value));
  };

  return (
    <div>
      <Textarea
        placeholder='Add some text here'
        size='sm'
        value={text}
        onChange={(e) => setText(e.target.value)}
        resize={"vertical"}
      />
      <Flex alignItems={"center"} justifyContent={"space-between"} mt={6} gap={6}>
        <Select value={voice?.name} onChange={handleVoiceChange}>
          {window.speechSynthesis.getVoices().map((voice) => (
            <option key={voice.name} value={voice.name}>{voice.name}</option>
          ))}
        </Select>
        <Flex alignItems={"center"} justifyContent={"center"} gap={6}>
          <Button colorScheme='blue' onClick={handlePlay}>{isPaused ? "Resume" : "Play"}</Button>
          <Button onClick={handlePause}>Pause</Button>
          <Button onClick={handleStop}>Stop</Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default TextToSpeech;